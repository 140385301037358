import React from "react"
import Seo from "../components/seo"

const Error = () => {
  return (
    <>
      <Seo title="عذراً - الصفحة غير موجودة" />
      <div className="columns is-centered">
        <div className="column is-three-quarters-desktop">
          <div className="message-wrapper">
            <p className="hero-title">404</p>
            <p>الصفحة غير موجودة!</p>
            <p>
              بإمكانك تصفح المقالات أو استخدام خاصية البحث للوصول إلى محتوى
              الموقع...
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Error
